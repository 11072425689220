@use "sass:math";
@import "./Input.module";

$radioCheckSize: $fontSize * 1.5;
.radio {
  position: relative;
  display: inline-block;

  font-size: $fontSize;
  min-height: $radioCheckSize;
  height: 100%;
  line-height: $radioCheckSize;
  padding-left: calc(#{$radioCheckSize} + 10px);

  user-select: none;
  cursor: pointer;

  input {
    display: none;
  }

  &.success > .checkmark {
    background-color: $successColor !important;
  }

  &.required > .checkmark {
    box-shadow: 0px 0px 0px 1px $dangerColor;
  }

  &.checked > .checkmark {
    background-color: $primaryColor;
    //outline-color: $textColor;
    box-shadow: 0px 0px 0px 1px $textColor;
  }

  .checkmark {
    position: absolute;

    background-color: $backgroundColor;
    border: $radioCheckSize * 0.15 solid $backgroundColor;
    //outline: 1px solid $textColorLight;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 1px $textColorLight;

    top: 0;
    left: 0;
    width: $radioCheckSize;
    height: $radioCheckSize;

    transition: all 0.3s;
  }

  &:hover {
    > .checkmark {
      //outline-color: $textColor;
      box-shadow: 0px 0px 0px 1px $textColor;
    }
  }
}

$radioButtonHeight: $fontSize * 4;
$radioButtonWidth: 300px;
$radioButtonPaddingLeft: 1rem;
$radioButtonPaddingTop: 0.5rem;
.radioButton {
  position: relative;
  display: inline-block;

  cursor: pointer;
  height: $radioButtonHeight;
  width: $radioButtonWidth;
  padding: $radioButtonPaddingTop $radioButtonPaddingLeft;
  border-radius: $borderRadius;

  transition: outline-color 0.5s, background 0.5s;

  input {
    display: none;
  }

  .radioText {
    height: 100%;
    align-items: center;
    display: flex;
    $availableWidth: calc(
      100% - 2 * #{$radioButtonPaddingLeft} - #{$radioCheckSize}
    );
    width: calc(1 * #{$availableWidth});
  }

  &:not(.checked) {
    @include outlined($textColorLight);

    &:hover {
      //outline-color: black;
      //box-shadow: 0px 0px 0px 1px $textColor;

      .checkmark {
        //outline-color: black;
        box-shadow: 0px 0px 0px 1px $textColor;
      }
    }
  }

  &.checked {
    @include pressed();
    font-weight: bold;

    .radioActive {
      display: none;
    }

    .checkmark {
      background: radial-gradient($primaryColor 50%, transparent 50%);
      //outline-color: $primaryColor;
      box-shadow: 0px 0px 0px 1px $primaryColor;
    }
  }

  .checkmark {
    position: absolute;
    background: transparent;
    $halfSize: math.div($radioCheckSize, 2);
    border-radius: 50%;
    //outline: 1px solid $textColorLight;
    box-shadow: 0px 0px 0px 1px $textColorLight;

    top: calc(50% - #{$halfSize});
    right: $radioButtonPaddingLeft;
    width: $radioCheckSize;
    height: $radioCheckSize;

    transition: all 0.5s;
  }

  overflow: hidden;
  z-index: 1;

  .radioActive {
    position: absolute;
    left: 50%;
    top: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    transition: height 1s, width 1s;
    background-color: transparentize(black, 0.8);
    z-index: -1;
  }

  &:active > .radioActive {
    width: 100% * 2.5;
    height: 100% * 2.5;
  }
}
