@use "sass:math";
@use "sass:color";

$primaryColor: #ff3c44;
$successColor: #28a745;
$dangerColor: #e04b4d;
$textColor: black;
$textColorLight: lighten($textColor, 80%);
$backgroundColor: white;
$backgroundLight: #f2f2f2;

$fontSize: 1rem;
$borderWidth: 1px;
$borderRadius: 8px;

@mixin iconButton() {
  cursor: pointer;
  transition: color 0.3s;
  &:hover {
    color: $primaryColor;
  }
}

@mixin outlined($clr) {
  border: $borderWidth * 1.5 solid $clr;
  //outline: $borderWidth solid $clr;
}

@mixin disabled() {
  //background-color: lighten($dangerColor, 25%);
  cursor: not-allowed !important;
  @include outlined($backgroundLight);
  color: $textColorLight;
}

@mixin pressed() {
  color: $primaryColor;
  background-color: transparentize($primaryColor, 0.75);
  @include outlined($primaryColor);
}

@mixin centeredAbsolute() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin loadingBar($animated: true) {
  border-radius: $borderRadius;
  background-color: $textColorLight;
  color: $textColorLight;

  cursor: not-allowed;

  @if ($animated) {
    opacity: 0.25;
    animation: 2s a infinite;
    @keyframes a {
      0% {
        opacity: 0.25;
      }
      40% {
        opacity: 0.6;
      }
      50% {
        opacity: 0.8;
      }
      60% {
        opacity: 0.6;
      }
      100% {
        opacity: 0.25;
      }
    }
  }
}

:export {
  primaryColor: $primaryColor;
  dangerColor: $dangerColor;
  textColor: $textColor;
  textColorLight: $textColorLight;
  backgroundColor: $backgroundColor;
  backgroundLight: $backgroundLight;

  borderRadius: $borderRadius;
}
