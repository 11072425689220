@import "./Input.module";

$menuPadding: 5px;
.menu {
  position: absolute;
  top: $inputHeight + 0.5rem;
  background: $backgroundColor;
  border-radius: $borderRadius;
  min-width: 100%;
  max-width: 150%;
  width: max-content;

  padding: $menuPadding;
  box-shadow: 0 0 10px 2px $textColorLight;

  z-index: 999;
}
