@import "../UI.module";

.panel {
  background-color: $backgroundLight;
  border-radius: $borderRadius;
  padding: 2rem;

  @media (max-width: 992px) {
    padding: 1rem;
  }
  //margin-left: 2rem;

  ul {
    list-style: none;
    padding: 0;

    li {
      padding: 0.5rem 0;

      i {
        width: 1.5rem;
        text-align: center;
      }
    }
  }

  ul.visit {
    padding-left: 0.5rem;

    i {
      transform: scale(0.5);
    }
  }
}
