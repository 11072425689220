@import "./Menu.module";
@import "./Input.module";

$optHeight: $fontSize + 2 * $inputPaddingLeft;
.menu {
  max-height: calc(#{$optHeight} * 5 + #{$menuPadding} * 2);
  overflow-y: auto;
}

.option {
  font-size: $fontSize;
  padding: $inputPaddingLeft;
  border-radius: $borderRadius;
  transition: background-color 0.3s;

  &:hover:not(.loading) {
    cursor: pointer;
    background-color: darken($backgroundColor, 10%);
  }

  &.loading > span {
    width: 100%;
    height: 100%;
    display: inline-block;

    @include loadingBar();
  }

  &.active {
    font-weight: bold;
  }
}
