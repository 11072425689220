@use "sass:math";
@import "../Menu.module";

$dateWidth: 200px;
.date {
  user-select: none;
  position: relative;
  max-width: $dateWidth;

  > input {
    cursor: pointer;
  }

  .menu {
    width: $dateWidth * 1.6;
    max-width: $dateWidth * 1.6;
    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .scrollDate {
    width: 100%;
    padding: 0;
    margin: 0;
    user-select: none;

    @media (max-width: 767px) {
      max-width: calc(100% - 2rem);
      position: fixed !important;
      left: 1rem;
      top: 2rem;
    }

    > div {
      width: 100%;
      margin: auto;
    }

    .controls > div {
      padding: 0;

      > div {
        min-width: 100% !important;
      }

      input {
        border-radius: 0;
        border-right-color: $backgroundColor !important;
        box-shadow: unset !important;
        outline: unset !important;
      }

      span {
        right: 8px;
      }

      &:first-child input {
        border-radius: $borderRadius 0 0 $borderRadius;
      }

      &:last-child input {
        border-radius: 0 $borderRadius $borderRadius 0;
        border-right-color: unset !important;
      }
    }
  }

  .header {
    font-size: $fontSize * 1.2;
    font-weight: bold;
    text-align: center;
    margin-top: $fontSize;
    margin-bottom: $fontSize;

    a {
      cursor: pointer;
    }

    span {
      position: absolute;
      width: 1rem;
      height: 1rem;
      display: inline-block;

      cursor: pointer;

      &:nth-child(1) {
        left: 1rem;
      }

      &:nth-child(2) {
        right: 1rem;
      }

      &.disabled {
        color: transparent;
        pointer-events: none;
      }
    }
  }

  .table {
    width: 100%;
    color: $textColor;

    th,
    td {
      width: math.div($dateWidth, 6);
      height: math.div($dateWidth, 6);
      text-align: center;
      transition: all 0.3s;
      border-radius: $borderRadius;
    }

    th {
      color: $textColorLight;
    }

    td {
      cursor: pointer;
    }

    td.active {
      background-color: $textColorLight;
      color: $primaryColor;
    }

    td.disabled {
      color: $textColorLight;
      cursor: default;
    }
  }

  .buttons {
    margin-bottom: $fontSize;

    span {
      width: 15%;
      display: inline-block;
      text-align: center;

      @include iconButton();
    }

    button {
      width: 85%;
      z-index: 1000;
    }
  }
}
