@import "../UI.module";

$inputPaddingTop: 0.75rem;
$inputPaddingLeft: 1rem;
$inputHeight: $fontSize + 2 * $inputPaddingTop;

@mixin activeLabel($mul: 0.67) {
  + label {
    top: -$fontSize * $mul * 0.5;
    font-size: $fontSize * $mul;
    padding: 0 2px;
    color: $textColor;
  }
}

.input {
  position: relative;
  display: inline-block;

  height: $inputHeight;
  min-width: 80px;
}

.loader {
  // DEPRECATED / EXPERIMENTAL
  display: none;

  width: calc(100% - #{$inputPaddingLeft} * 2 + 4px);
  height: calc(100% - #{$inputPaddingTop} * 2 + 4px);
  z-index: 998;

  @include centeredAbsolute();
  @include loadingBar(false);
}

.joined {
  .input:first-child {
    width: 50% !important;

    & > .control {
      border-radius: $borderRadius 0 0 $borderRadius;
    }
  }

  .input:last-child {
    width: 50% !important;

    & > .control {
      border-radius: 0 $borderRadius $borderRadius 0;
    }
  }
}

.input > .control {
  outline: none !important;

  font-size: $fontSize;
  border-radius: $borderRadius;
  background: $backgroundColor;

  width: 100%;
  height: 100%;
  padding: 0 $inputPaddingLeft;

  &::placeholder {
    color: transparent;
    transition: color 0.5s;
  }

  @include outlined($textColor);
  color: $textColor;
  font-weight: normal;

  transition: outline 0.5s, border 0.5s;

  &:placeholder-shown {
    @include outlined($textColorLight);
  }

  &:not(:placeholder-shown) {
    @include activeLabel();
  }

  &:focus {
    @include outlined($textColor);
    @include activeLabel();
  }

  &:disabled {
    @include disabled();

    &::placeholder {
      color: $textColorLight;
      font-weight: normal;
    }

    + label {
      display: none;
    }

    ~ .icon {
      color: $textColorLight;
    }
  }

  &.error {
    color: $dangerColor;
    @include outlined($dangerColor);

    + label {
      color: $dangerColor !important;
      font-weight: normal;
    }
  }
}

.input > .icon {
  position: absolute;
  display: inline-block;

  top: $inputPaddingTop;
  right: $inputPaddingLeft;
  pointer-events: none;

  transition: color 0.5s;
  background-color: $backgroundColor;
}

.input > label {
  position: absolute;
  display: inline-block;

  font-size: $fontSize;
  font-weight: normal;
  color: $textColor;
  top: $inputPaddingTop;
  left: $inputPaddingLeft;
  pointer-events: none;

  background: linear-gradient(
    0,
    $backgroundColor calc(50% + #{$borderWidth}),
    transparent 50%
  );
  transition: all 0.3s;
}

.input > p.error {
  color: $dangerColor;
  position: relative;
  top: 5px;
  left: 0;
  margin: 0;

  animation: anim 0.5s;
  @keyframes anim {
    0% {
      left: -0.5rem;
    }
    50% {
      left: 1rem;
    }
    100% {
      left: 0;
    }
  }
}

.input.clickable {
  //cursor: pointer;

  > .control {
    cursor: pointer;
  }
}

.input.loading {
  > .control {
    cursor: not-allowed;
  }
}
